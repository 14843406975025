import React from 'react';
import logo from './logo.svg';
import comixery from './comixery.svg'
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
      </header>
      <main className='App-main'>
        <button>Coming This Fall</button>
      </main>
      <footer className='App-footer'>
        <img src={comixery} className="footer-logo" alt="logo" />
      </footer>
    </div>
  );
}

export default App;
